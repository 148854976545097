import { Component } from '@angular/core';
import {
  MOMENT_DATE_FORMAT,
  MOMENT_DATE_TIME_FORMAT_SLASH,
} from '@app/app.constants';
import { PdfPreviewTemplateStateService } from '@app/modules/pdf-request-form/services/pdf-preview-template-state.service';
import {
  CancerHistoryStatus,
  ExtendedProductInventoryItem,
  ExtendedServiceData,
  PregnancyType,
  PriorScreeningTestType,
  ServiceData,
} from '@app/modules/service-data/service-data.types';
import { anonymizeName, calculateSimpleCode } from '@core/utils/core.util';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pdf-preview-template',
  templateUrl: './pdf-preview-template.component.html',
  styleUrls: ['./pdf-preview-template.component.scss'],
})
export class PdfPreviewTemplateComponent {
  protected moment = moment;
  protected readonly MOMENT_DATE_FORMAT = MOMENT_DATE_FORMAT;
  protected readonly MOMENT_DATE_TIME_FORMAT_SLASH =
    MOMENT_DATE_TIME_FORMAT_SLASH;
  protected anonymizeName = anonymizeName;

  protected simpleCode: string;

  protected pii: ExtendedProductInventoryItem;
  protected serviceData: ExtendedServiceData;
  protected previousSampleKitId: string;

  protected patientBMI$: Observable<boolean>;

  protected CancerHistoryStatus = CancerHistoryStatus;
  protected PregnancyType = PregnancyType;
  protected PriorScreeningTestType = PriorScreeningTestType;

  constructor(private state: PdfPreviewTemplateStateService) {
    this.pii = state.options.pii;
    this.previousSampleKitId = state.options.previousSampleKitId;
    this.serviceData = this.pii.sample.serviceData;
    this.simpleCode = calculateSimpleCode(this.pii.kitId);
  }

  protected getFullName(serviceData: ServiceData): string {
    return (
      serviceData.patientInformation.firstName +
      ' ' +
      serviceData.patientInformation.lastName
    );
  }
}
